import React from 'react';
import Logo from '../../Images/logo.png';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer-cont" id="footer">
            {/* <img src={FatBoy} alt="" id="fat-boy" /> */}
            <div className="container-medium">
                <div className="footer">
                    <div className="footer-left">
                        <img src={Logo} className="footer-logo"/>
                    </div>
                    <div className="footer-right">
                        <div class="artboard">
                            <div class="button" onclick="onclick">twitter</div>
                            <div class="links" > <a style={{color:'black'}} target="_blank" href="https://twitter.com/junglejim_game">@junglejim_game</a></div>
                        </div>
                        <div class="artboard">
                            <div class="button" onclick="onclick">Telegram</div>
                            <div class="links"> <a style={{color:'black'}} target="_blank" href="https://t.me/junglejim_game">junglejim_game</a></div>
                        </div>
                        <div class="artboard">
                            <div class="button" onclick="onclick">Discord</div>
                            <div class="links"> <a style={{color:'black'}} target="_blank" href="https://t.me/junglejim_game">junglejim_game</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';
import Boy from '../../Images/boy.png';
import "./Gameplay.css";

export default function Gameplay() {
    
    return (
        <div className="gameplay-cont" id="gameplay">
            <img src={Boy} alt="" id="boy" />
            <div className="container-medium">
                <div className="gameplay">
                    <div className="gameplay-title-cont">
                        gameplay
                    </div>
                    <div className="gameplay-vid-cont">
                        <video autoPlay="true" loop muted controls className='gameplay-vid'>
                            <source src='https://silly-mcclintock-9c3399.netlify.app/static/media/bgvid1.4fab9901d2a4ee9454f1.mp4'/>
                        </video>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

import React from 'react';
import About from '../Components/About/About';
import Gameplay from '../Components/Gameplay/Gameplay';
import Intro from '../Components/Intro/Intro';
import Navbar from '../Components/Navbar/Navbar';
import NavbarSmall from '../Components/Navbar/NavbarSmall';
import Roadmap from '../Components/Roadmap/Roadmap';
import Team from '../Components/Team/Team';
import Footer from '../Components/Footer/Footer';

export default function HomePage() {
  return (
    <div className="App">
        <div className="container-large">
            <Navbar/>
            <NavbarSmall/>
            <Intro/>
            <About/>
            <Gameplay/>
            <Roadmap/>
            <Team/>
            <Footer/>
        </div>
    </div>
    )
}

import React from 'react';
// import Logo from '../../Images/text.png';
import { Link , useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import "./Navbar.css";

export default function Navbar() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    React.useEffect(()=>{
        window.addEventListener("scroll",function(){
            const nav=document.getElementsByClassName("navbar-cont")[0];
            if(window.scrollY>0){
                if(nav?.style){
                    nav.style.boxShadow="0 7px 13px 0 rgb(0 0 0 / 10%)";
                    nav.style.backgroundColor="black";
                }
            }else{
                if(nav?.style){
                    nav.style.boxShadow="none";
                    nav.style.backgroundColor="#ffffff00";
                }
            }
        });
    },[])
    

    return (
        <div className="navbar-cont" id="lg-nav">
            <div className="container-medium">
                <div className="navbar">
                    <div className="navbar-logo">
                        {/* <Link to="/">
                            <img src={Logo} id="nav-logo"/>
                        </Link> */}
                       
                    </div>
                    <div className="navbar-menu">
                        <div className="nav-item"><a href='#about'>About us</a></div>
                        <div className="nav-item" ><a onClick={handleOpen} style={{textTransform:'uppercase',cursor:'pointer'}}>Buy Coins</a></div>
                        <div className="nav-item"><a onClick={handleOpen} style={{textTransform:'uppercase',cursor:'pointer'}}>nft</a></div>
                        <div className="nav-item"><a href='#team'>team</a></div>
                        <div className="nav-item"><a href='./litepaper.pdf'>Litepaper</a></div>
                        
                        <div className="nav-item">
                            {/* <Link to="/login"> 
                                <div className="nav-btn-cont">
                                Play to earn
                                </div>
                            </Link> */}
                            <div className="nav-btn-cont" style={{cursor:'pointer'}}onClick={handleOpen}>
                                Play to earn
                            </div>
                        </div>

                    </div>
                </div>
                <Modal
        open={open}
        onClose={handleClose}
      >
        <div className='modal-cont'>
          <div className='modal-text'>
            Coming Soon...
          </div>
        </div>
      </Modal>
            </div>
        </div>    
    )
}

import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Images/logo.png';
import Wave from '../../Images/wave.png';
import "./Intro.css";

export default function Intro() {
    
    return (
        <div className="intro-cont">
            {/* <img src={Wave} id="intro-wave"></img> */}
            {/* <img src={Boy} alt="" id="intro-boy" /> */}
            <div className="container-medium">
                <div className="intro">
                    <div className="intro-logo-cont">
                        <img src={Logo} id="intro-logo" />
                    </div>
                    <div className="intro-btn-cont">
                        <a href='https://play.junglejim.games/' target="_blank">
                            <button className="intro-btn">Play for free</button>
                        </a>
                        {/* <button className="intro-btn">Play For Free</button> */}
                    </div>
                </div>
            </div>
        </div>
        
    )
}

import React from 'react';
import Candy from '../../Images/candy.png';
import Candy2 from '../../Images/candy2.png';
import "./Roadmap.css";

export default function Roadmap() {
    
    return (
        <div className="roadmap-cont" id="roadmap">
            <div className="container-medium">
                <div className="roadmap">
                    <div className="roadmap-title-cont">
                        roadmap
                    </div>
                    <div>
                    
<div class="timeline"> 
  <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
    <div class="timeline__event__icon ">
      <img  style={{width:'100px'}} src={Candy2}></img>

    </div>
    <div class="timeline__event__date">
      Q4 2023
    </div>
    <div class="timeline__event__content ">
      <div class="timeline__event__title">
      Market Research & Analysis
      </div>
      {/* <div class="timeline__event__description">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
      </div> */}
    </div>
  </div>
  <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
    <div class="timeline__event__icon" style={{backgroundColor:'rgba(0,0,0,0)'}}>
    <img  style={{width:'100px'}} src={Candy}></img>

    </div>
    <div class="timeline__event__date">
      Q1 2024 & Q2 2024
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
      Game development
      </div>
      {/* <div class="timeline__event__description">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
      </div> */}
    </div>
  </div>
  <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
    <div class="timeline__event__icon" style={{backgroundColor:'rgba(0,0,0,0)'}}>
    <img  style={{width:'100px'}} src={Candy2}></img>

    </div>
    <div class="timeline__event__date">
      Q3 2024
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
      Launch Game, Token Generation Event,Update game with more characters
    
      </div>
      {/* <div class="timeline__event__description">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
      </div> */}

    </div>
  </div>
  <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
    <div class="timeline__event__icon" style={{backgroundColor:'rgba(0,0,0,0)'}}>
    <img  style={{width:'100px'}} src={Candy}></img>

    </div>
    <div class="timeline__event__date">
      Q4 2024
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
      Launch NFT Marketplace, Introduce Tournaments, Multiplayer Mode
      </div>
      {/* <div class="timeline__event__description">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
      </div> */}
    </div>
  </div>
  <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
    <div class="timeline__event__icon" style={{backgroundColor:'rgba(0,0,0,0)'}}>
    <img  style={{width:'100px'}} src={Candy2}></img>

    </div>
    <div class="timeline__event__date">
      Q1 2025
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
      Launch on Android & IOS , Introduce new Maps
    
      </div>
      {/* <div class="timeline__event__description">
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
      </div> */}

    </div>
  </div>

</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import { Link } from 'react-router-dom';
import Circle from '../../Images/circle.png';
import Tri from '../../Images/tri.png';
import Ayush from '../../Images/Ayush.jpg';
import Gautam from '../../Images/Gautam.jpg';
import Harsh from '../../Images/harsh.jpg';
import Ishaan from '../../Images/Ishaan.jpeg';
import Pent from '../../Images/pent.png';
import "./Team.css";

export default function Team() {
    
    return (
        <div className="team-cont" id="team">
            <img src={Circle} id="shape-1" className="team-shape" />
            <img src={Tri} id="shape-2" className="team-shape" />
            <img src={Pent} id="shape-3" className="team-shape" />
            <div className="container-medium">
                <div className="team">
                    <div className="team-title-cont">
                        Team
                    </div>
                    
                    <div className="team-cont-1">             
                        <div className="team-card-cont">
                            <div className="team-card">
                                <div className="team-img-cont">
                                <img className="team-img" src={Harsh}/>
                                </div>
                                <div className="team-name">
                                Harsh
                                </div>
                                <div className="team-pos">
                                CEO
                                </div>
                            </div>
                        </div>

                        <div className="team-card-cont">
                            <div className="team-card">
                                <div className="team-img-cont">
                                <img className="team-img" src={Gautam}/>
                                </div>
                                <div className="team-name">
                                Gautam
                                </div>
                                <div className="team-pos">
                                COO
                                </div>
                            </div>
                        </div>

                        <div className="team-card-cont">
                            <div className="team-card">
                                <div className="team-img-cont">
                                <img className="team-img" src={Ishaan}/>
                                </div>
                                <div className="team-name">
                                Ishaan
                                </div>
                                <div className="team-pos">
                                Lead Dev
                                </div>
                            </div>
                        </div>

                        <div className="team-card-cont">
                            <div className="team-card">
                                <div className="team-img-cont">
                                <img className="team-img" src={Ayush}/>
                                </div>
                                <div className="team-name">
                                Ayush
                                </div>
                                <div className="team-pos">
                                Studio Head
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react';
import { Link } from 'react-router-dom';
import FatBoy from '../../Images/candy.png';
import Candy from '../../Images/candy.png';
import Rocket from '../../Images/rocket.png';
import Candy2 from '../../Images/candy2.png';
import "./About.css";

export default function About() {
    
    return (
        <div className="about-cont" id="about">
            {/* <img src={FatBoy} alt="" id="fat-boy" /> */}
            <div className="container-medium">
                <div className="about">
                    <div className="about-title-cont">
                        about us
                    </div>
                    <div className="about-text-cont">
                        Get ready for a complete package of fun and excitement with this game of Jungle Jim. The goal of the Jungle Jim, in this game is to climb up a tree as high as possible, while collecting goodies on the way, and jumping past obstacles to beat previous personal best scores

                        As your quest progresses you will come across obstacles like ant, snake, spider, cactus, and deadly plant. So, keep your fingers active and fast to save Jungle boy from danger that lurks around.

                        You can choose to play as either Boggy, Ooga Wooga, or Oozie. The choice is yours. As you progress ahead you will also win a number of amazing power ups like magnet, score multiplier, candy doubler, and daddy candy.

                        So, what are you waiting for? Get your fingers tapping, start climbing and put your skills to the test.
                    </div>
                    {/* <div className="about-text-cont">
                        Get ready for a complete package of fun and excitement with this game of Jungle Jim. The goal of the Jungle Jim, in this game is to climb up a tree as high as possible, while collecting goodies on the way, and jumping past obstacles to beat previous personal best scores

                        As your quest progresses you will come across obstacles like ant, snake, spider, cactus, and deadly plant. So, keep your fingers active and fast to save Jungle boy from danger that lurks around.

                        You can choose to play as either Boggy, Ooga Wooga, or Oozie. The choice is yours. As you progress ahead you will also win a number of amazing power ups like magnet, score multiplier, candy doubler, and daddy candy.

                        So, what are you waiting for? Get your fingers tapping, start climbing and put your skills to the test.
                    </div> */}
                    <div className="about-img-cont">
                        <img src={Rocket} id="about-rocket" className="about-img" />
                        <img src={Candy2} alt="" className="about-img" />
                        <img src={Candy} alt="" className="about-img" />
                    </div>
                </div>
            </div>
        </div>
        
    )
}
